// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
  query WebAuthnRecover($email: String!, $token: String!) {
    publicKeyOptions: webauthnRecover(email: $email, token: $token) {
      rp {
        name
      }
      pubKeyCredParams {
        type,
        alg
      }
      challenge
      attestation
      user {
        name,
        id,
        displayName
      }
      authenticatorSelection {
        requireResidentKey,
        userVerification
      }
      timeout
    }
  }`;

export default QUERY;
